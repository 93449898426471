<template>
  <div class="page">
    <LandingHeader/>
    <section id="contacts" class="contacts" style="flex: 1 0 auto;">
      <div class="container">
        <div class="contacts__inner row">
          <div class="contacts__info">
            <h2 class="title title_contacts">
              Join pro
            </h2>
            <p
                class="contacts__descr"

            >Full access to all options</p>

          </div>
        </div>
        <div class="row" :style="`flex-direction:${$store.state.isMobile?'column':'row'};`">
          <div style="padding:15px;" :style="`width:${$store.state.isMobile?'100%':'50%'}`">
            <v-data-table
              :headers="headers"
              :items="items"
              :disable-sort="true"
              :mobile-breakpoint="0"
              class="elevation-1"
              hide-default-footer
          >
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-for="item in items" :key="item.id">
                <td class="d-block d-sm-table-cell" style="border-color:#fff;">
                  {{item.name}}
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center;border-color:#fff;">
                  <v-icon v-if="item.free" color="#75d16d">mdi-check</v-icon>
                  <v-icon v-else>mdi-close</v-icon>
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center;border-color:#fff;">
                  <v-icon v-if="item.pro" color="#75d16d">mdi-check</v-icon>
                  <v-icon v-else>mdi-close</v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
          </div>
          <div style="padding:15px;" :style="`width:${$store.state.isMobile?'100%':'50%'}`">
            <div style="width:100%;display: flex;align-items: center;border:2px solid #eee;border-radius:10px;padding:20px 30px;height:150px;margin-bottom: 15px;">
              <div style="display: flex;flex-direction: column;flex:1;">
                <div>Free</div>
                <div>$0</div>
              </div>
              <div style="display: flex;flex:1;justify-content: flex-end">
                <v-btn href="/registration" color="rgb(117, 209, 109)" outlined>Subscribe now</v-btn>
              </div>
            </div>
            <div style="width:100%;display: flex;align-items: center;border:2px solid #eee;border-radius:10px;padding:20px 30px;height:150px;">
              <div style="display: flex;flex-direction: column;flex:1;">
                <div>Pro</div>
                <div>$7</div>
                <div style="font-size:12px;color:#3d3d3d;">Per month, billed each month ($7)</div>
              </div>
              <div style="display: flex;flex:1;justify-content: flex-end">
                <v-btn href="/registration" color="rgb(117, 209, 109)" outlined>Subscribe now</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <LandingFooter/>
  </div>
</template>

<script>
export default {
  name: "LandingPricing",
  data(){
    return{
      headers:[
        { text: ""},
        { text: "Free"},
        { text: "Pro"},
      ],
      items:[
        {
          name:"Ad banner on your landing page",
          free:true,
          pro:false,
        },
        {
          name:"management",
          free:false,
          pro:true
        },
        {
          name:"Domain",
          free:false,
          pro:true
        },
        {
          name:"Full access to reviews",
          free:false,
          pro:true
        },
        {
          name:"PWA",
          free:false,
          pro:true
        },
        {
          name:"Custom scripts",
          free:false,
          pro:true
        },
        {
          name:"Weather",
          free:false,
          pro:true
        },
      ]
    }
  },
  components: {
    LandingHeader:() => import("@/components/Landing/LandingHeader"),
    LandingFooter:() => import("@/components/Landing/LandingFooter")
  },
  mounted() {
    document.title = 'Pricing';
  }
}
</script>
<style lang="scss" scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper){
  background-color: #fff;
}
</style>
<style lang="scss">
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 40px;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

span,
time,
label {
  display: inline-block;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
  }
}

img,
svg {
  max-width: 100%;
  height: auto;
}

button,
input,
optgroup,
select,
textarea {
  line-height: 1.5;
}

button {
  cursor: pointer;
}
@import "@/scss/landing/style.scss";
</style>
